import { DateIsInThePast } from "../../../util/date/dateUtils";

export const applyFiltersAndSort = (offers, filters, selectedSortOption) => {
    let filteredOffers = filterOffers(offers, filters);

    switch (selectedSortOption) {
        case "Promoted Packages":
            sortPromotedPackages(filteredOffers);
            break;
        case "Price (Lowest to Highest)":
            sortByPriceAsc(filteredOffers);
            break;
        case "Price (Highest to Lowest)":
            sortByPriceDesc(filteredOffers);
            break;
        case "Trustpilot Score (Highest to Lowest)":
            sortByTrustpilotDesc(filteredOffers);
            break;
        case "Trustpilot Score (Lowest to Highest)":
            sortByTrustpilotAsc(filteredOffers);
            break;
        default:
            break;
    }

    return filteredOffers;
};

const filterOffers = (offers, filters) => {
    const [minSpeed, maxSpeed] = filters.speed.split(' - ').map(Number);

    return offers.filter(offer => {
        return (
            (filters.speed === "All" || (offer.speed >= minSpeed && offer.speed <= maxSpeed)) &&
            (filters.monthlyCost === "" || parseInt(checkIfSpecialOffer(offer) ? offer.offerPrice : offer.monthlyCost) <= parseInt(filters.monthlyCost)) &&
            (filters.contractLength.length === 0 || filters.contractLength.includes(offer.contractLength + '')) &&
            (filters.packageType.length === 0 || filters.packageType.includes(offer.packageTypeText)) &&
            (filters.serviceProvider.length === 0 || filters.serviceProvider.includes(offer.serviceProvider))
        );
    });
}

const sortByPriceAsc = (offers) => {
    offers.sort((a, b) =>
        (checkIfSpecialOffer(a) ? a.offerPrice : a.monthlyCost) -
        (checkIfSpecialOffer(b) ? b.offerPrice : b.monthlyCost)
    );
}

const sortByPriceDesc = (offers) => {
    offers.sort((a, b) =>
        (checkIfSpecialOffer(b) ? b.offerPrice : b.monthlyCost) -
        (checkIfSpecialOffer(a) ? a.offerPrice : a.monthlyCost)
    );
}

const sortByTrustpilotAsc = (offers) => {
    offers.sort((a, b) => {
        if (a.trustPilotScore.score === b.trustPilotScore.score) {
            if (b.isPromoted && !a.isPromoted) return 1;
            if (a.isPromoted && !b.isPromoted) return -1;
            return 0;
        }
        return a.trustPilotScore.score - b.trustPilotScore.score;
    });
}

const sortByTrustpilotDesc = (offers) => {
    offers.sort((a, b) => {
        if (b.trustPilotScore.score === a.trustPilotScore.score) {
            if (b.isPromoted && !a.isPromoted) return 1;
            if (a.isPromoted && !b.isPromoted) return -1;
            return 0;
        }
        return b.trustPilotScore.score - a.trustPilotScore.score;
    });
}

const sortPromotedPackages = (offers) => {
    const promotedOffers = offers.filter(offer => offer.isPromoted).sort((a, b) => a.monthlyCost - b.monthlyCost);
    const specialOffers = offers.filter(offer => !offer.isPromoted && checkIfSpecialOffer(offer)).sort((a, b) => a.monthlyCost - b.monthlyCost);
    const remainingOffers = offers.filter(offer => !offer.isPromoted && !checkIfSpecialOffer(offer)).sort((a, b) => a.monthlyCost - b.monthlyCost);

    offers.splice(0, offers.length, ...promotedOffers, ...specialOffers, ...remainingOffers);
}

export const checkIfSpecialOffer = (offer) => {
    if (!offer.isSpecialOffer) {
        return false;
    }

    if (!offer.offerExpirationDate) {
        return true;
    }

    return (!DateIsInThePast(offer.offerExpirationDate));
}

export const getMinCost = (offers) => {
    return Math.ceil(Math.min(...offers.map((offer) => checkIfSpecialOffer(offer) ? offer.offerPrice : offer.monthlyCost)));
}

export const getMaxCost = (offers) => {
    return Math.ceil(Math.max(...offers.map((offer) => checkIfSpecialOffer(offer) ? offer.offerPrice : offer.monthlyCost)));
}
