import { referralsList } from '../../constants/constants';
import { Button } from 'flowbite-react';
import { Card } from 'flowbite-react';
import Datepicker from 'react-tailwindcss-datepicker';
import Input from '../common/input/input';
import Ms3Button from '../common/button/button';
import Ms3Dropdown from '../common/dropdown/dropdown';
import dayjs from 'dayjs';
import { useState } from 'react';
import { validateField } from '../../util/validation/validation';
import InstallationExpectations from './installationExpectations';
import InstallationVideo from './installationVideo';

const Installation = ({ availableAppointments, installationDetails, updateInstallationDetails, onSubmit }) => {

  const [availableMeridians, setAvailableMeridians] = useState(['AM', 'PM']);
  const [errors, setErrors] = useState({
    onSiteLocation: false,
    referralOption: false,
    leadReferralSource: false,
  });

  const validateFields = (name, value) => {
    const valid = validateField(name, value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !valid,
    }));

    return valid;
  };

  const handleDateChange = (date) => {
    const values = availableAppointments.filter((appointment) => appointment.appointmentDate === date.startDate);

    if (values && values.length > 0) {
      setAvailableMeridians(values.map((value) => value.appointmentSlot));
    } else {
      setAvailableMeridians(['AM', 'PM']);
    }

    updateInstallationDetails({
      ...installationDetails,
      customerRequestedDate: date.startDate,
      timeSlot: values && values[0] ? values[0].appointmentSlot : null,
      appointmentReference: values && values[0] ? values[0].appointmentReference : null
    });
  };

  const setMeridian = (value) => {
    updateInstallationDetails({
      ...installationDetails,
      timeSlot: value,
      appointmentReference: getAppointmentReference(installationDetails.customerRequestedDate, value)
    });
  };

  const getAppointmentReference = (date, meridian) => {
    const values = availableAppointments.filter((appointment) => appointment.appointmentDate === date && appointment.appointmentSlot === meridian);
    if (!values || !values[0]) {
      return "";
    }

    return values[0].appointmentReference;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateInstallationDetails({
      ...installationDetails,
      [name]: value,
    });

    validateFields(name, value);
  };

  const setReferralOption = (option) => {
    const referralValue = (option === 'Other, please state' ? "" : option);

    updateInstallationDetails({
      ...installationDetails,
      referralOption: option,
      leadReferralSource: referralValue
    });

    validateFields('referralOption', option);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var hasErrors;
    const requiredFields = ['onSiteLocation', 'leadReferralSource', 'referralOption'];
    requiredFields.forEach((field) => {
      if (!validateFields(field, installationDetails[field])) {
        hasErrors = true;
      }
    });

    if (!hasErrors) {
      onSubmit();
    }
  };

  const getMinDate = () => {
    const dates = availableAppointments.map((appointment) => appointment.appointmentDate);
    return new Date(dates[0]);
  };

  const getMaxDate = () => {
    const dates = availableAppointments.map((appointment) => appointment.appointmentDate);
    return new Date(
      dayjs(dates[dates.length - 1])
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    );
  };

  const getDisabledDates = () => {
    const dates = availableAppointments.map((appointment) => appointment.appointmentDate);

    const lastDay = dayjs(dates[dates.length - 1]);
    const days = lastDay.diff(dates[0], 'day');

    const disabledDates = [];
    for (var i = 1; i <= days; i++) {
      const nextDay = dayjs(dates[0]).add(i, 'day').format('YYYY-MM-DD');
      if (!dates.includes(nextDay)) {
        disabledDates.push(nextDay);
      }
    }

    return disabledDates.map((disabledDate) => ({
      startDate: disabledDate,
      endDate: disabledDate,
    }));
  };

  return (
    <div className="md:my-20 my-5 flex flex-col items-center md:mx-0 mx-3">
      <Card className="md:max-w-3xl max-w-md md:w-3/5 md:p-10 p-3 shadow-ms3-blue-xl border-0">
        <form className="flex flex-col">
          <div className="text-lg mb-5 text-ms3-blue font-bold">Installation Date</div>

          <Datepicker
            asSingle={true}
            readOnly={true}
            useRange={false}
            popoverDirection="down"
            placeholder={'Installation Date'}
            displayFormat='DD/MM/YYYY'
            value={{
              "startDate": installationDetails.customerRequestedDate,
              "endDate": installationDetails.customerRequestedDate
            }}
            onChange={handleDateChange}
            minDate={getMinDate()}
            maxDate={getMaxDate()}
            inputClassName="w-full rounded-lg focus:ring-0 border-gray-300 p-3 focus:border-ms3-blue"
            disabledDates={getDisabledDates()}
          />

          <div className="text-lg mt-10 text-ms3-blue font-bold">Time Slot</div>
          <div className="flex flex-row mb-10 gap-5">
            {availableMeridians.map((meridian) => (
              <Button
                outline={!installationDetails.timeSlot || installationDetails.timeSlot.toUpperCase() !== meridian}
                size="xl"
                value={meridian}
                className="font-bold text-white mt-10 focus:ring-0 bg-ms3-blue focus:bg-ms3-blue"
                onClick={() => setMeridian(meridian)}>
                {meridian}
              </Button>
            ))}
          </div>

          <Input
            name="onSiteLocation"
            label="On Site Location"
            placeholder="e.g. Living Room, Hallway, etc."
            shortPlaceholder="Location"
            value={installationDetails.onSiteLocation}
            error="Location is invalid"
            onChange={handleChange}
            invalid={errors.onSiteLocation}
          />

          <Input
            name="accessInstructions"
            label="Access Instructions"
            placeholder="e.g. Gate code: 56732"
            shortPlaceholder="Instructions"
            value={installationDetails.accessInstructions}
            onChange={handleChange}
          />

          <Input
            name="specialAccessInstructions"
            label="Additional Information"
            placeholder="Anything else you’d like us to know"
            shortPlaceholder="Information"
            value={installationDetails.specialAccessInstructions}
            onChange={handleChange}
          />

          <Ms3Dropdown
            label="Where did you hear about us?"
            options={referralsList}
            value={installationDetails.referralOption}
            selectOption={setReferralOption}
            placeholder="Option"
            invalid={errors.referralOption}
          />

          {installationDetails.referralOption === 'Other, please state' && (
            <Input
              name="leadReferralSource"
              label=""
              placeholder="Please state where you heard about us"
              value={installationDetails.leadReferralSource}
              error="Value is invalid"
              onChange={handleChange}
              invalid={errors.leadReferralSource}
            />
          )}

          <Ms3Button id="instrallationNextButton" onClick={handleSubmit} text="Continue to confirmation" />
        </form>

        <InstallationExpectations />
        <InstallationVideo />
      </Card>
    </div>
  );
};

export default Installation;
