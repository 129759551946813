import TagManager from 'react-gtm-module';

export const trackEvent = (eventName, eventCategory, eventProperties) => {

    const eventData = {
        event: eventName,
        event_category: eventCategory,
        event_properties: eventProperties
    }

    try {
        TagManager.dataLayer({
            dataLayer: eventData
        });

    } catch (error) {
        console.error("Failed to track event: ", eventData.event);
    }
}