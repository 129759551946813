import { Modal } from "flowbite-react";
import { HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi';
import Ms3Button from "../common/button/button";

const WEBSITE_BASE_URL = process.env.REACT_APP_WEBSITE_BASE_URL;

const CompletionModal = ({ error, errorDetails, orderReference, openModal, onClose }) => {

    const buildUrl = () => {
        return WEBSITE_BASE_URL + "/order-complete";
    }

    return (
        <Modal size="2xl" show={openModal} onClose={onClose}>
            <Modal.Body>
                <div className="text-center">

                    {!error && (
                        <>
                            <HiOutlineCheckCircle className="mx-auto mb-4 text-6xl text-ms3-pink" />

                            <h3 className="mb-10 text-lg font-bold">
                                Order Successfully Placed: {orderReference}
                            </h3>

                            <div className="flex justify-center gap-4">
                                <a href={buildUrl()}>
                                    <Ms3Button id="completionModalCloseOnSuccessButton" text="Close" />
                                </a>
                            </div>
                        </>
                    )}

                    {error && (
                        <>
                            <HiOutlineXCircle className="mx-auto mb-4 text-6xl text-ms3-red" />

                            <h3 className="mb-5 text-lg font-bold">
                                Could not place order
                            </h3>

                            {errorDetails && errorDetails.length > 0 && errorDetails.map((e, index) => (
                                <div className="flex flex-row items-center justify-center text-md"
                                    key={`${index}`}>{e.ErrorDescription}
                                </div>
                            ))}

                            <div className="flex justify-center gap-4 mt-5">
                                <Ms3Button id="completionModalCloseOnErrorButton" onClick={onClose} text="Close" />
                            </div>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CompletionModal;