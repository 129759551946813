import { Card } from 'flowbite-react';
import Ms3Button from '../../common/button/button';

const WEBSITE_BASE_URL = process.env.REACT_APP_WEBSITE_BASE_URL;

const OfferAlert = ({ address }) => {

    const buildUrl = () => {
        return WEBSITE_BASE_URL +
            "/create-alert" +
            "?fullAddress=" + address.fullAddress +
            "&buildingNumber=" + address.buildingNumber +
            "&street=" + address.street +
            "&postcode=" + address.postcode
    }

    return (
        <Card className="max-w-xs shadow-ms3-blue-xl rounded-2xl mt-10">

            <div className='text-lg font-bold'>Not ready to switch?</div>

            <div className='text-xs mb-2'>
                Set a reminder and when the time comes we'll check the market to find a great deal for you to switch to.
            </div>

            <div className='text-xs mb-2'>
                We want to save you money so we'll also let you know when your new contract expires and when your provider raises their prices.
            </div>

            <a href={buildUrl()} target='_blank' rel="noreferrer" className='flex flex-col items-center'>
                <Ms3Button id="createAlertButton" text="Create an alert" />
            </a>
        </Card>
    )
}

export default OfferAlert;