import { useEffect, useState, useRef } from "react";
import { Dropdown } from "flowbite-react";
import Offer from "../offer/offer";
import OfferFilters from "../filter/filter";
import Rfs from "../rfs/rfs";
import { sortOptions } from "../../../constants/constants";
import { applyFiltersAndSort, getMaxCost, getMinCost } from "../utils/offerUtils";
import { trackEvent } from "../../../gtm/gtm";

const OfferList = ({ address, offers, purchase }) => {

    const [filters, setFilters] = useState({
        speed: "All",
        monthlyCost: getMinCost(offers),
        contractLength: [],
        packageType: [],
        serviceProvider: []
    });
    const [shouldScroll, setShouldScroll] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState("Promoted Packages");

    const providers = [...new Set(offers.map(offer => offer.serviceProvider))].sort();

    const filteredAndSortedOffers = applyFiltersAndSort(offers, filters, selectedSortOption);

    const filterSectionRef = useRef(null);

    useEffect(() => {
        setFilters({
            ...filters,
            monthlyCost: getMaxCost(offers)
        });
    }, [offers]);

    useEffect(() => {
        if (shouldScroll && filterSectionRef.current) {
            filterSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [filters]);

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        setShouldScroll(true);

        const mergedFilters = {...filters, ...newFilters()};
        const numberOfDeals = applyFiltersAndSort(offers, mergedFilters, selectedSortOption).length;

        trackEvent('filtering_changed', "deals", {
            filters: mergedFilters,
            numberOfDeals: numberOfDeals
        });
    };

    const sort = (option) => {
        trackEvent('sort_changed', 'deals', { sortOption: option });

        setSelectedSortOption(option);
    }

    return (
        <div className="flex flex-col items-center md:mx-0 mx-3">
            <Rfs address={address} />

            <div ref={filterSectionRef} className="flex md:flex-row flex-col gap-5">
                <OfferFilters address={address} providers={providers} offers={offers} filters={filters} setFilters={handleFilterChange} />

                <div>
                    {(offers && offers.length > 0) && (
                        <>
                            <div className="flex md:flex-row flex-col gap-2 justify-between items-center mb-5">
                                <div className='font-bold md:text-lg text-md'>
                                    Showing {filteredAndSortedOffers.length} out of {offers.length} packages
                                </div>

                                <div className="flex flex-row items-center">
                                    <div className="font-bold mr-3 md:text-lg text-md">Sort: </div>
                                    <div className="border border-purple-100 rounded-xl p-2">
                                        <Dropdown label={selectedSortOption} inline className="relative">
                                            {sortOptions.map((option) => (
                                                <Dropdown.Item
                                                    key={option}
                                                    className="focus:text-ms3-pink focus:bg-transparent hover:bg-transparent"
                                                    onClick={() => sort(option)}
                                                >
                                                    {option}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col items-center">
                                {filteredAndSortedOffers.map((offer) => (
                                    <Offer key={`package-${offer.id}`} offer={offer} purchase={purchase} />
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OfferList;