import { Modal } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useApiClient } from '../../api/useApiClient';
import Loader from '../common/loader/loader';
import Input from '../common/input/input';
import Ms3Button from '../common/button/button';
import GhostButton from '../common/button/ghostButton';

const PhoneValidation = ({ customerDetails, openModal, setOpenModal, handleValidation }) => {

    const { callApi, loading, error } = useApiClient();

    const [validationCode, setValidationCode] = useState("");
    const [invalid, setInvalid] = useState(false);
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        setInvalid(false);
        setValidationCode("");
        setCanResend(false);

        let timer;
        if (openModal) {
            timer = setTimeout(() => setCanResend(true), 15000);
        }
        return () => clearTimeout(timer);
    }, [openModal]);

    const handleChange = (e) => {
        setInvalid(false);
        setValidationCode(e.target.value);
    };

    const validatePhoneNumber = async (e) => {
        e.preventDefault();

        const response = await callApi('confirmOtp', {
            "phoneNumber": customerDetails.phoneNumber,
            "code": validationCode
        });

        if (response && response.valid) {
            handleValidation();
        } else {
            setInvalid(true);
        }
    }

    const resendCode = async (e) => {
        e.preventDefault();

        await callApi('generateOtp', {
            "phoneNumber": customerDetails.phoneNumber
        });

        setCanResend(false);
        setTimeout(() => setCanResend(true), 15000);
    }

    return (
        <Modal size="3xl" show={openModal} onClose={() => setOpenModal(false)} dismissible>
            <Modal.Body>
                <div className="p-5">
                    <form className="flex flex-col gap-10">
                        <Input
                            name="code"
                            label={`Enter the code received on ${customerDetails.phoneNumber}. The code will expire in 5 minutes.`}
                            placeholder="Code"
                            value={validationCode}
                            onChange={handleChange}
                            invalid={invalid}
                            error={error ? "Exception validating phone number. Please try again." : "The code you entered is invalid."}
                        />

                        <div className='flex flex-col gap-5 items-center'>
                            <Ms3Button id="phoneValidationNextButton" text="Continue to installation" disabled={loading} onClick={validatePhoneNumber} />
                            <GhostButton id="resendCodeButton" text="Resend code" disabled={loading} hidden={!canResend} onClick={resendCode} />
                        </div>
                    </form>
                </div>

                {loading && <Loader />}
            </Modal.Body>
        </Modal>
    )
}

export default PhoneValidation;
