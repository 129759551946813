import { Card, Checkbox } from 'flowbite-react';
import Input from '../common/input/input';
import { useState } from 'react';
import { validateField } from '../../util/validation/validation';
import Ms3Button from '../common/button/button';
import PhoneValidation from './phoneValidation';
import { agreementText } from '../../constants/constants';
import { useApiClient } from '../../api/useApiClient';
import Loader from '../common/loader/loader';

const CustomerDetails = ({ customerDetails, updateCustomerDetails, stepCompleted, onSubmit }) => {

    const { callApi, loading } = useApiClient();

    const [openModal, setOpenModal] = useState(false);
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        let formattedValue = newValue;
        if (name === 'phoneNumber') {
            formattedValue = newValue.replace(/[^0-9\s]/g, '');

            if (newValue.startsWith('+')) {
                formattedValue = '+' + formattedValue.replace(/\+/g, '');
            }
        }

        updateCustomerDetails((prevDetails) => ({
            ...prevDetails,
            [name]: formattedValue
        }));

        validateFields(name, formattedValue);
    };

    const validateFields = (name, value) => {
        var valid = validateField(name, value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: !valid,
        }));

        return valid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        var hasErrors;
        const requiredFields = ['firstName', 'lastName', 'phoneNumber', 'email'];
        requiredFields.forEach(field => {
            if (!validateFields(field, customerDetails[field])) {
                hasErrors = true;
            }
        });

        if (!hasErrors) {
            if (stepCompleted) {
                onSubmit();
            } else {
                await callApi('generateOtp', {
                    "phoneNumber": customerDetails.phoneNumber
                })
                setOpenModal(true);
            }
        }
    };

    const handleValidation = () => {
        setOpenModal(false);
        onSubmit();
    };

    return (
        <div className="md:my-20 my-5 flex flex-col items-center md:mx-0 mx-3">
            <Card className="md:max-w-3xl max-w-md md:w-3/5 md:p-10 p-3 shadow-ms3-blue-xl border-0">
                <form className="flex flex-col md:gap-4" onSubmit={handleSubmit}>
                    <div className="md:flex flex-row items-center justify-between md:mb-5 gap-24">
                        <Input
                            className="mt-10"
                            name="firstName"
                            label="Enter your first name"
                            placeholder="First Name"
                            value={customerDetails.firstName}
                            onChange={handleChange}
                            invalid={errors.firstName} />

                        <Input
                            className="mt-10"
                            name="lastName"
                            label="Enter your last name"
                            placeholder="Last Name"
                            value={customerDetails.lastName}
                            onChange={handleChange}
                            invalid={errors.lastName} />
                    </div>

                    <div className="md:flex flex-row items-center justify-between md:mb-5 gap-24">
                        <Input
                            className="mt-10"
                            name="phoneNumber"
                            label="Enter your phone number"
                            placeholder="Phone Number"
                            value={customerDetails.phoneNumber}
                            onChange={handleChange}
                            invalid={errors.phoneNumber} />

                        <Input
                            className="mt-10"
                            name="email"
                            label="Enter your email"
                            placeholder="Email"
                            value={customerDetails.email}
                            onChange={handleChange}
                            invalid={errors.email} />
                    </div>

                    <div className="flex flex-row items-center gap-2 mb-5">
                        <Checkbox
                            id="agreement"
                            name="agreement"
                            value={customerDetails.agreement}
                            size="xl"
                            checked={customerDetails.agreement}
                            onChange={handleChange}
                            className="text-ms3-blue ring-0 focus:ring-0 cursor-pointer bg-white"
                        />
                        <label htmlFor="agreement" className="text-sm text-gray-500 cursor-pointer text-justify leading-relaxed">{agreementText}</label>
                    </div>

                    <Ms3Button id="customerDetailsNextButton" text={stepCompleted ? "Continue to installation" : "Validate phone number"} />
                </form>
            </Card>

            <PhoneValidation {...{ customerDetails, openModal, setOpenModal, handleValidation }} />

            {loading && (<Loader />)}
        </div>
    );
}

export default CustomerDetails;
