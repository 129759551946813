import { useState } from 'react';
import Ms3Button from '../../common/button/button';
import FiltersAccordion from './filtersAccordion';
import FiltersModal from './filtersModal';

const OfferFilters = ({ address, providers, offers, filters, setFilters }) => {

    const [isFiltersVisible, setIsFiltersVisible] = useState(false);

    const toggleFiltersVisibility = () => {
        setIsFiltersVisible(!isFiltersVisible);
    };

    return (
        <div className='sticky top-0 bg-white z-10'>
            <div className="md:hidden mb-2 flex flex-row justify-center pt-2">
                <Ms3Button id="toggleFilterVisibilityButton" onClick={toggleFiltersVisibility} text={isFiltersVisible ? 'Hide Filters' : 'Show Filters'} />
            </div>

            <FiltersAccordion address={address} providers={providers} offers={offers} filters={filters} setFilters={setFilters} />

            <FiltersModal showModal={isFiltersVisible} closeModal={() => setIsFiltersVisible(false)} address={address} providers={providers} offers={offers} filters={filters} setFilters={setFilters} />
       </div>
    );
}

export default OfferFilters;