import { useState } from 'react';
import OfferDetailsModal from '../details/offerDetailsModal';
import Ms3Button from '../../common/button/button';
import OfferProviderDetails from './offerProviderDetails';
import OfferDetails from './offerDetails';
import OfferOptions from './offerOptions';
import { GoInfo } from "react-icons/go";
import SpecialOfferBadge from './specialOfferBadge';
import PromotedOfferBadge from './promotedOfferBadge';
import { ms3PhoneNumber } from '../../../constants/constants';
import { checkIfSpecialOffer } from '../utils/offerUtils';
import { trackEvent } from '../../../gtm/gtm';

const Offer = ({ offer, purchase }) => {

    const [openModal, setOpenModal] = useState(false);

    const onSubmit = () => {
        trackEvent('order_now_button_pressed', 'deals', {
            triggerScreen: 'Deal Card',
            packageDetails: offer
        });

        purchase(offer);
    }

    const clickCallNow = () => {
        trackEvent('call_now_button_pressed', 'deals');
    }

    const clickMoreDetails = () => {
        trackEvent('more_details_button_pressed', 'deals');

        setOpenModal(true);
    }

    const activeSpecialOffer = checkIfSpecialOffer(offer);

    return (
        <div className="relative mb-10">
            <SpecialOfferBadge show={activeSpecialOffer && !offer.isPromoted} offer={offer} />
            <PromotedOfferBadge offer={offer} />

            <div className={`max-w-md md:max-w-6xl shadow-ms3-blue-xl border-0 flex md:flex-row flex-col rounded-2xl justify-between border-4 ${activeSpecialOffer || offer.isPromoted ? 'border-ms3-pink' : 'border-gray-300'}`}>
                <OfferProviderDetails offer={offer} />

                <div className='md:w-5/6 md:pt-8 md:pb-5 pb-3 flex flex-col md:gap-7 gap-1 md:mx-5'>
                    <OfferDetails offer={offer} activeSpecialOffer={activeSpecialOffer} />

                    <div className='flex md:flex-row flex-col md:gap-10'>
                        <OfferOptions offer={offer} />

                        <div className='md:w-1/4 flex flex-col gap-2 justify-evenly items-center'>
                            <Ms3Button id="orderNowButton" onClick={onSubmit} text="Order Now" />

                            <a onClick={clickCallNow} href={`tel:${ms3PhoneNumber}`} className="md:text-md text-sm font-bold text-gray-900 cursor-pointer hover:text-ms3-pink">
                                Call Now
                            </a>

                            <div className="md:text-md text-sm font-bold flex flex-row gap-1 items-center text-gray-900 cursor-pointer hover:text-ms3-pink" onClick={clickMoreDetails}>
                                <span>More Details</span>
                                <GoInfo className='md:text-xl text-lg' />
                            </div>
                        </div>
                    </div>
                </div>

                <OfferDetailsModal openModal={openModal} onClose={() => setOpenModal(false)} activeSpecialOffer={activeSpecialOffer} offer={offer} purchase={purchase} />
            </div>
        </div>

    )
}

export default Offer;